import React, { Component, Suspense } from 'react';
import {  Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
// import { setUser } from '../../views/_components/actions';
// import { fetchingMultipleDataAPI } from '../../views/_utils/fetchingDataAPI'
import PropTypes from 'prop-types';
import {
  // AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
import {navigationRole} from '../../views/_defaultVariable';
// routes config
import routes from '../../routes';
require('../../views/custom.css');

const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));
require('../../views/custom.css');
class DefaultLayout extends Component {
 constructor(props) {
   super(props);
   this.state = {
    username : '',
    info : 0
   };
 }

componentDidMount(){
  //   fetchingMultipleDataAPI([`playlogstatushint/`]).then(result => result[0].data).then(data => {
  //   this.setState({
  //     info : data.fail
  //   })
  // })
}

loading = () => <div className="animated fadeIn pt-2 text-center"><img src={'assets/img/LOGO_ALQOLA.png'} alt='Alqolam Logo' style={{width:89,height:40}}/></div>

  render() {
    const userRole = this.props.role;
    return (
      <div className="app">
        <AppHeader fixed  style={{border:'none', boxShadow:'0px 0px 15px 5px rgba(0,0,0,0.07)'}}>
          <Suspense  fallback={this.loading()}>
            <DefaultHeader info={this.state.info} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
            <AppSidebarNav navConfig={navigationRole(navigation, routes, userRole)} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main" style= {{backgroundColor: '#f9f9fc'}}>
            <div><AppBreadcrumb appRoutes={routes} /></div>
            <Container fluid className="container-fluid-custom">
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component {...props} />
                        )} />
                    ) : (null);
                    })}
                </Switch>
              </Suspense>
            </Container>
          </main>
          {/* defaultaside HERE*/}
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  role : PropTypes.object.isRequired,
  isFetching : PropTypes.bool.isRequired,
  authenticated : PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({ authenticated : state.isAuthenticated, role : state.user , isFetching : state.isFetching });
export default connect(mapStateToProps, null)(DefaultLayout);