import React, { Component } from 'react';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { Provider } from 'react-redux';
import  Routes  from './views/_components/Routes';  
import { store } from './views/_components/store';  
require('dotenv').config()
// import { LocalizeProvider } from 'react-localize-redux'

class App extends Component {

render() {
    return (
      // <Provider store={store}>
      //   <PersistGate loading={null} persistor={persistor}>
      //     <Routes />
      //   </PersistGate>
      // </Provider>
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}

export default App;
