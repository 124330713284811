import axios from 'axios'
import Cookies from 'js-cookie';
export const SET_FETCHING = 'SET_FETCHING';
export const SET_TOKEN= 'SET_TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const SET_USER = 'SET_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_AUTH = 'SET_AUTH';
export const MSG_CODE = 'MSG_CODE';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export function setFetching(v) {
  return { type: SET_FETCHING, state: v };
}

export function setUser(user) {
  return { type: SET_USER, user };
}

export function setAuthenticated(auth) {
  return { type: SET_AUTH, state: auth };
}

export function setToken(token) {
  return { type: SET_TOKEN , token };
}

export function deleteUser() {
  return { type: DELETE_USER };
}

export function deleteToken() {
  return { type: DELETE_TOKEN };
}

export function setMsgCode(msg) {
  return { type: MSG_CODE, state: msg };
}

export function actionGetUsername(values) {
   return dispatch => {
     axios.get(`${process.env.REACT_APP_URL}current_user/`, {
        headers: {
          'Authorization': `Token ${values.token}`,
        }
      })
        .then(response => response.data)
        .then(json => {
        
           dispatch(setUser(json));
           //localStorage.setItem("user", JSON.stringify(json));
           Cookies.set('LOG_USER', JSON.stringify(json), { path: '' })
           dispatch(setFetching(false));
           dispatch(setAuthenticated(true));
        });
    }
  };

export function actionTryLogin(values) {
    return dispatch => {
    dispatch(setFetching(true));
    axios.post(`${process.env.REACT_APP_URL}login/`, values)
    .then(response => response.data)
    .then(data => {
      const token = data;
      if (token !== null) {
        dispatch(setToken(token));
        dispatch(actionGetUsername(token));
        //localStorage.setItem("token", JSON.stringify(token));
        Cookies.set('LOG_APP', {token : token.token}, { path: '' })
      }
    })
    .catch(err => {
      if(err.response.status === 404 || err.response.status === 401){
        dispatch(setMsgCode('Invalid password or username'))
      }else{
        dispatch(setMsgCode('Error Connection'))
      }
      dispatch(setFetching(false));
      dispatch(setAuthenticated(false));
    });
  };
}
