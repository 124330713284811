import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// const persistConfig = {
//   key: 'primary',
//   storage,
// }
// const persistedReducers = persistReducer(persistConfig, rootReducer)


// const store = createStore(persistedReducers,compose(
//         applyMiddleware(thunk),
//         devToolsEnhancer(),
//       ))
// const persistor =   persistStore(store)

// export { store, persistor}

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
)
export { store }
