export default {
  items: [
    // {
    //   name: 'Buttons',
    //   url: '/buttons',
    //   icon: 'icon-cursor',
    //   children: [
    //     {
    //       name: 'Buttons',
    //       url: 'buttons/buttons',
    //       icon: 'icon-cursor',
    //     },
    //     {
    //       name: 'Buttons',
    //       url: 'buttons/buttons',
    //       icon: 'icon-cursor',
    //     }
    //   ],
    // },
    {
      title: true,
      name: 'Analytics',
      url: '/dashboard',
      children: [
        {
          name: 'PlayLog Analytics',
          url: '/Analytics/PlaylogAnalytics',
          icon: 'fas fa-clock',
        },
      ],           // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'PlayLog Analytics',
      url: '/Analytics/PlaylogAnalytics',
      icon: 'fas fa-clock',
    },
    {
      title: true,
      name: 'Show Users',
      url: '/dashboard',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: '',            // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'All Users',
      url: '/ShowUsers/AllUsers',
      icon: 'icon-user',
    },
    {
      name: 'Real Time Live Users',
      url: '/ShowUsers/RealTimeLiveUsers',
      icon: 'icon-pie-chart',
    },
    {
      name: 'Total User Connected',
      url: '/ShowUsers/TotalUserConnected',
      icon: 'cui-user',
    },
    {
      title: true,
      name: 'Play history',
      url: '/dashboard',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Content',
      url: '/PlayHistory/Content',
      icon: 'fas fa-clipboard-list',
    },
    {
      name: 'Play Log',
      url: '/PlayHistory/PlayLog',
      icon: 'fas fa-clipboard-check',
    },
    {
      name: 'Unknown Log',
      url: '/PlayHistory/UnknownLog',
      icon: 'fas fa-comment-slash',
    },
    // {
    //   name: 'Blocked Log',
    //   url: '/PlayHistory/Blocked',
    //   icon: 'fas fa-ban',
    // },
    // {
    //   name: 'Error Log',
    //   url: '/PlayHistory/ErrorLog',
    //   icon: 'far fa-times-circle',
    // },
    {
      name: 'Behaviours',
      url: '/PlayHistory/Behaviours',
      icon: 'fas fa-laugh-beam',
    },
    {
      title: true,
      name: 'Ads Management',
      // url: '/ads',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name:'Ads Packages',
      url: '/AdsManagement/AdsPackages',
      icon: 'fa fa-cubes',
    },
    {
      name:'Ads Client',
      url: '/AdsManagement/AdsClient',
      icon: 'fa fa-users',
    },
    {
      name:'Ads Management',
      url: '/AdsManagement/AdsManagement',
      icon: 'fa fa-bullhorn',
    },
    {
      name:'Ads Statistic',
      url: '/AdsManagement/AdsStatistic',
      icon: 'fa fa-line-chart',
    },
    {
      title: true,
      name: 'Subscription',
      // url: '/ads',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name:'Subscription',
      url: '/Subscription/Subscription',
      icon: 'fa fa-handshake-o',
    },
    // {
    //   name:'List Ads Management',
    //   url: '/AdsManagement/ListAdsManagement',
    //   icon: 'icon-list',
    // }
  ],
};
