import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component,role, authed, ...rest }) => (
    <Route {...rest} render={props => {
    	let roleCompareStatus = false
		    if (authed) {
                if(role.userRole.username.match(/admin.*/) && !role.userRole.group.length){
                   roleCompareStatus = true
                }
                if(role.userRole.group.length){
                    for(let i = 0 ; i < role.componentRole.length ; i ++){
                        if(role.componentRole.includes(role.userRole.group[i].name)){
                            roleCompareStatus = true
                            break;
                        }
                    }
                }
                if(roleCompareStatus){
                    return <Component {...props} />
                }else{
                    return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                }
       		 }else{
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
             }
    }}/>
)