// import * as moment from 'moment/moment';
import moment from 'moment';

const today = new Date();

// const hourago = new Date(today.getTime() - (1000*60*60));

export const timeNowLocal = moment(today).local().format('YYYY-MM-DD HH:mm:ss');
export const toDateLocal  = (date) => {
    return moment(date).local().format('YYYY-MM-DD')
} 
export const lastMomentAgo = (minutes) => {
  const timeAgoInMinutes = new Date(today.getTime() - (1000*60*minutes));
  const formatTime = moment(timeAgoInMinutes).local().format('YYYY-MM-DD HH:mm:ss');
  return formatTime
}

export const test = {
    "links": {
        "next": null,
        "previous": null
    },
    "count": 10,
    "page_size": 1000,
    "results": [
        {
            "total": 1,
            "updatetime": "2019-06-11T14:01:00",
            "calculate": 56
        },
        {
            "total": 2,
            "updatetime": "2019-06-11T13:56:00",
            "calculate": 52
        },
        {
            "total": 2,
            "updatetime": "2019-06-11T13:56:00",
            "calculate": 51
        },
        {
            "total": 1,
            "updatetime": "2019-06-11T13:48:00",
            "calculate": 43
        },
        {
            "total": 2,
            "updatetime": "2019-06-11T13:35:00",
            "calculate": 30
        },
        {
            "total": 2,
            "updatetime": "2019-06-11T13:30:00",
            "calculate": 25
        },
        {
            "total": 1,
            "updatetime": "2019-06-11T13:25:00",
            "calculate": 20
        },
        {
            "total": 4,
            "updatetime": "2019-06-11T13:15:00",
            "calculate": 10
        },
        {
            "total": 1,
            "updatetime": "2019-06-11T13:10:55",
            "calculate": 5
        },
        {
            "total": 2,
            "updatetime": "2019-06-11T13:10:00",
            "calculate": 5
        },
          {
            "total": 3,
            "updatetime": "2019-06-11T13:05:00",
            "calculate": 0
        },
          {
            "total": 5,
            "updatetime": "2019-06-11T13:05:00",
            "calculate": 0
        },
        {
            "total": 1,
            "updatetime": "2019-06-11T13:05:00",
            "calculate": 0
        }
        ,
        {
            "total": 1,
            "updatetime": "2019-06-11T13:05:00",
            "calculate": 3
        }
        ,
        {
            "total": 1,
            "updatetime": "2019-06-11T13:05:00",
            "calculate": 4
        }
        ,
        {
            "total": 1,
            "updatetime": "2019-06-11T13:05:00",
            "calculate": 1
        }
    ]
}

const minutes = [0,5,10,15,20,25,30,35,40,45,50,55,60]

const _getClosestValues = function(a, x) {
    let lo , hi , presition , max, min;
    for (let i = a.length; i--;) {
        if (a[i] <= x && (lo === undefined || lo < a[i])) lo = a[i];
        if (a[i] >= x && (hi === undefined || hi > a[i])) hi = a[i];
    };
    max = hi - x
    min = x - lo
    presition = max >= min ? lo : hi
    return presition;
}

const _formatData = (value) => {
  let firstGrouping = {};
  let finalGrouping = {};
  let transform  = null;
  value.results.forEach( x => { 
      firstGrouping[x.calculate] = (firstGrouping[x.calculate] || 0) + x.total;
    });
  Object.keys(firstGrouping).map((key, i) => {
       transform = minutes.includes(parseInt(key)) ? key : _getClosestValues(minutes,key);
       finalGrouping[transform] = (finalGrouping[transform] || 0) + firstGrouping[key];
       return finalGrouping
    })
  return finalGrouping
}

export const reGroupData = (val) => {
  let formatData = _formatData(val)
  return formatData
}


export const navigationRole = (nav,routes,userRole) => {
  let navigationPass = [] 
  let obj = {items :[]}
      if(userRole.username.match(/admin.*/) && !userRole.group.length){
        obj = nav
      }else{
          userRole.group.map((v,s) => {
            return routes.map((y,t) => { 
                if(y.role.includes(v.name)){
                     navigationPass.push(y.path) 
                }
                return navigationPass
            })
        })
         const filtered = nav.items.filter(function(value, index, arr){
                return  navigationPass.includes(value.url)
            })
         Object.assign(obj.items , filtered)
    }
  return obj
}



