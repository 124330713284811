import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {
  SET_FETCHING,
  SET_USER,
  DELETE_USER,
  SET_TOKEN,
  DELETE_TOKEN,
  SET_AUTH,
  MSG_CODE
} from './actions';
import Cookies from 'js-cookie';
const persistDefault = {
};
const userDefault = {
}
const tokenDefault = {
  token : ''
}


//Object.assign({},{new_username: action.user.username});
//Object.assign({}, state, {new_value: 'blah blah'})
//Object.assign(state, action.token);
function user(state = userDefault, action) {
  switch (action.type) {
    case SET_USER:
      return Object.assign({},state,{
        username: action.user.username , group : action.user.groups});
    case DELETE_USER:
      return userDefault;
    default:
      return state;
  }
}

function token(state = tokenDefault, action) {
  switch (action.type) {
    case SET_TOKEN:
      return Object.assign(state, action.token);
    case DELETE_TOKEN:
      return tokenDefault;
    default:
      return state;
  }
}

function msgCode(state = '', action) {
  switch (action.type) {
      case MSG_CODE: 
        return action.state;
      default: 
        return state;
  }
}

function isFetching(state = false, action) {
    switch (action.type) {
        case SET_FETCHING: 
          return action.state;
        default: 
          return state;
  }
}

function isAuthenticated(state = false, action) {
    switch (action.type) {
        case SET_AUTH: 
          return action.state;
        default: 
          return state;
  }
}

function persistedState() {
  try {
    const serializedState = Cookies.get('LOG_APP') || null
    if (serializedState === null) return null
    return JSON.parse(serializedState)
  } catch(e) {
    return null
  }
}

const persistedStates = persistedState == null ? persistDefault : persistedState;

// export default {
//     user,
//     token,
//     isFetching,
//     persistedStates,
//     form: formReducer
// }

export default combineReducers({
    user,
    token,
    isFetching,
    msgCode,
    isAuthenticated,
    persistedStates,
    form: formReducer
});