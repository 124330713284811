import React from 'react';
import DefaultLayout from './containers/DefaultLayout';

const AdsManagement = React.lazy(() => import('./views/AdsManagement/AdsManagement'));
const AdsStatistic = React.lazy(() => import('./views/AdsManagement/AdsManagement/AdsStatistic'));
const AdsPackages = React.lazy(() => import('./views/AdsManagement/AdsManagement/AdsPackages'));
const AdsClient = React.lazy(() => import('./views/AdsManagement/AdsManagement/AdsClient'));
// const Ads = React.lazy(() => import('./views/AdsManagement/AdsManagement'));
const ListAdsManagement = React.lazy(() => import('./views/AdsManagement/AdsManagement/ListAdsManagement'));
const Breadcrumbs = React.lazy(() => import('./views/Base/Breadcrumbs'));
const Cards = React.lazy(() => import('./views/Base/Cards'));
const Carousels = React.lazy(() => import('./views/Base/Carousels'));
const Collapses = React.lazy(() => import('./views/Base/Collapses'));
const Dropdowns = React.lazy(() => import('./views/Base/Dropdowns'));
const Forms = React.lazy(() => import('./views/Base/Forms'));
const Jumbotrons = React.lazy(() => import('./views/Base/Jumbotrons'));
const ListGroups = React.lazy(() => import('./views/Base/ListGroups'));
const Navbars = React.lazy(() => import('./views/Base/Navbars'));
const Navs = React.lazy(() => import('./views/Base/Navs'));
const Paginations = React.lazy(() => import('./views/Base/Paginations'));
const Popovers = React.lazy(() => import('./views/Base/Popovers'));
const ProgressBar = React.lazy(() => import('./views/Base/ProgressBar'));
const Switches = React.lazy(() => import('./views/Base/Switches'));
const Tables = React.lazy(() => import('./views/Base/Tables'));
const DataTables = React.lazy(() => import('./views/Base/DataTables'));
const DataTablesCollapse = React.lazy(() => import('./views/Base/DataTablesCollapse'));
const Tabs = React.lazy(() => import('./views/Base/Tabs'));
const Tooltips = React.lazy(() => import('./views/Base/Tooltips'));
const BrandButtons = React.lazy(() => import('./views/Buttons/BrandButtons'));
const ButtonDropdowns = React.lazy(() => import('./views/Buttons/ButtonDropdowns'));
const ButtonGroups = React.lazy(() => import('./views/Buttons/ButtonGroups'));
const Buttons = React.lazy(() => import('./views/Buttons/Buttons'));
const Charts = React.lazy(() => import('./views/Charts'));
const PlaylogAnalytics = React.lazy(() => import('./views/Analytics/PlaylogAnalytics'));
const AllUsers = React.lazy(() => import('./views/ShowUsers/AllUsers'));
const RealTimeLiveUsers = React.lazy(() => import('./views/ShowUsers/RealTimeLiveUsers'));
const TotalUserConnected = React.lazy(() => import('./views/ShowUsers/TotalUserConnected'));
const Content = React.lazy(() => import('./views/PlayHistory/Content'));
const PlayLog = React.lazy(() => import('./views/PlayHistory/PlayLog'));
const Blocked = React.lazy(() => import('./views/PlayHistory/Blocked'));
const ErrorLog = React.lazy(() => import('./views/PlayHistory/ErrorLog'));
const UnknownLog = React.lazy(() => import('./views/PlayHistory/UnknownLog'));
const Behaviours = React.lazy(() => import('./views/PlayHistory/Behaviours'));
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const CoreUIIcons = React.lazy(() => import('./views/Icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/Icons/Flags'));
const FontAwesome = React.lazy(() => import('./views/Icons/FontAwesome'));
const SimpleLineIcons = React.lazy(() => import('./views/Icons/SimpleLineIcons'));
const Alerts = React.lazy(() => import('./views/Notifications/Alerts'));
const Badges = React.lazy(() => import('./views/Notifications/Badges'));
const Modals = React.lazy(() => import('./views/Notifications/Modals'));
const Colors = React.lazy(() => import('./views/Theme/Colors'));
const Typography = React.lazy(() => import('./views/Theme/Typography'));
const Widgets = React.lazy(() => import('./views/Widgets/Widgets'));
const Users = React.lazy(() => import('./views/Users/Users'));
const User = React.lazy(() => import('./views/Users/User'));
const Testing = React.lazy(() => import('./views/Testing/Testing'));

const Subscription = React.lazy(() => import('./views/Subscription/Subscription'));

const admin = 'admin';
const content = 'content';
const staff = 'staff';
const ads = 'ads';
const all = [ admin, content, staff , ads];
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout , role: all},
  { path: '/dashboard', name: 'Dashboard', component: Dashboard  , role: [admin,content]},
  { path: '/Testing/Testing', name: 'Test', component: Testing  , role: [ ads , content] },
  // { path: '/AdsManagement/Ads', name: 'Ads', component: Ads  , role :[admin]},
  { path: '/AdsManagement/AdsManagement', name: 'Ads Management', component: AdsManagement  , role :[admin]},
  { path: '/AdsManagement/AdsStatistic', name: 'Ads Statistic', component: AdsStatistic  , role :[admin]},
  { path: '/AdsManagement/AdsPackages', name: 'Ads Packages', component: AdsPackages  , role :[admin]},
  { path: '/AdsManagement/AdsClient', name: 'Ads Client', component: AdsClient  , role :[admin]},
  { path: '/AdsManagement/ListAdsManagement', name: 'List Ads Management', component: ListAdsManagement  , role :admin},
  { path: '/theme', exact: true, name: 'Theme', component: Colors  , role :all},
  { path: '/theme/colors', name: 'Colors', component: Colors , role :all },
  { path: '/theme/typography', name: 'Typography', component: Typography  , role :all},
  { path: '/base', exact: true, name: 'Base', component: Cards  , role: all},
  { path: '/base/cards', name: 'Cards', component: Cards  , role: all},
  { path: '/base/forms', name: 'Forms', component: Forms  , role :all},
  { path: '/base/switches', name: 'Switches', component: Switches  , role: all},
  { path: '/base/tables', name: 'Tables', component: Tables  , role :all},
  { path: '/base/datatables', name: 'Data Tables', component: DataTables  , role: all},
  { path: '/base/datatablescollapse', name: 'Data Tables', component: DataTablesCollapse  , role: all},
  { path: '/base/tabs', name: 'Tabs', component: Tabs  , role :all},
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs  , role :all},
  { path: '/base/carousels', name: 'Carousel', component: Carousels  , role :all},
  { path: '/base/collapses', name: 'Collapse', component: Collapses  , role :all},
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns  , role :all},
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons  , role :all},
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups  , role :all},
  { path: '/base/navbars', name: 'Navbars', component: Navbars  , role :all},
  { path: '/base/navs', name: 'Navs', component: Navs  , role :all},
  { path: '/base/paginations', name: 'Paginations', component: Paginations,  role :all},
  { path: '/base/popovers', name: 'Popovers', component: Popovers  , role: all},
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar  , role :all},
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips  , role: all},
  { path: '/buttons', exact: true, name: 'Buttons', component: Buttons  , role :all},
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons  , role :all},
  { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns  , role: all},
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups  , role :all},
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons  , role :all},
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons  , role :all},
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons  , role: all},
  { path: '/icons/flags', name: 'Flags', component: Flags  , role :all},
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome  , role:all},
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons  , role :all},
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts  , role :all},
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts  , role: all},
  { path: '/notifications/badges', name: 'Badges', component: Badges  , role: all},
  { path: '/notifications/modals', name: 'Modals', component: Modals  , role :all},
  { path: '/widgets', name: 'Widgets', component: Widgets  , role :all},
  { path: '/charts', name: 'Charts', component: Charts  , role: all},
  { path: '/Analytics/PlaylogAnalytics', name: 'Playlog Analytics', component: PlaylogAnalytics  , role :all},
  { path: '/ShowUsers/AllUsers', name: 'All Users', component: AllUsers  , role :all},
  { path: '/ShowUsers/RealTimeLiveUsers', name: 'Real Time Live Users', component: RealTimeLiveUsers  , role: all},
  { path: '/ShowUsers/TotalUserConnected', name: 'Total User Connected', component: TotalUserConnected  , role :all},
  { path: '/PlayHistory/Content', name: 'Content', component: Content  , role :all},
  { path: '/PlayHistory/PlayLog', name: 'Play Log', component: PlayLog  , role :all},
  { path: '/PlayHistory/Blocked', name: 'Blocked', component: Blocked  , role :all},
  { path: '/PlayHistory/UnknownLog', name: 'UnknownLog', component: UnknownLog  , role :all},
  { path: '/PlayHistory/ErrorLog', name: 'ErrorLog', component: ErrorLog  , role :all},
  { path: '/PlayHistory/Behaviours', name: 'Behaviours', component: Behaviours , role : all },
  { path: '/users', exact: true,  name: 'Users', component: Users  , role: all},
  { path: '/users/:id', exact: true, name: 'User Details', component: User  , role :all},
  { path: '/Subscription/Subscription', name: 'Subscription', component: Subscription  , role :[admin]},
];

export default routes;
